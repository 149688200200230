import React from "react";
import { Container, Row, Col, Button} from 'react-bootstrap';

export default function IndexPage(){

    return (
        <>
        
             <Container fluid className="__bg-primary-sl-expired">
                <Container>
                    <Row className="fade-in">
                        <Col lg={12}>
                            <div className="__introduction">
                                <h1 className="text-center text-white text-expired">
                                     PENAWARAN BERAKHIR
                                </h1>
                                {/* <p className="text-center text-white text-expired-p">Penawaran Attraction Mastery sudah berakhir agar kamu tidak melawatkan <br/> kesempatan ini lagi Yuk Join Waiting List dengan klik button  <br/> di bawah ini dan dapatkan penawaran spesial.</p> */}
                            </div>
                        </Col>
                        <Col lg={12}>
                            <div className="__image_logo-sl">
                                <img 
                                    src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovetraining.id/commitment-mastery/Ladingpage-plc/Logo-CM.png"
                                    alt="logo" 
                                    className="img img-fluid"
                                    />
                            </div>
                        </Col>
                        <Col lg={12}>
                            {/* <div className="__introduction">
                                <p className="text-center text-white text-expired-p">Join waiting list Commitment Mastery tanggal 21 - 30 May 2021, <br/> catat tanggalnya agar kamu tidak melewatkan kesempatan ini.</p>
                            </div> */}
                        </Col>
                        
                    </Row>
                </Container>
            </Container>

        </>
    )
}

